import leagueService from "./../api/league";

const state = {
    league: null,
    leagueStandings: [],
    leagueMatches: [],
    loadingLeague: false
};

const getters = {
    league: state => {
        return state.league;
    },

    leagueStandings: state => {
        return state.leagueStandings;
    },

    leagueMatches: state => {
        return state.leagueMatches;
    },

    loadingLeague: state => {
        return state.loadingLeague;
    }
};

const mutations = {
    setLeague(state, league) {
        state.league = league;
    },

    setLeagueStandings(state, standings) {
        state.leagueStandings = standings;
    },

    setLeagueMatches(state, matches) {
        state.leagueMatches = matches;
    },

    setLeagueLoading(state, status) {
        state.loadingLeague = status;
    }
};

const actions = {
    getLeague({ dispatch, commit }, id) {
        commit("setLeagueLoading", true);
        leagueService.getLeague(id).then(response => {
            commit("setLeague", response.data.data);
            commit("setLeagueLoading", false);
        });
    },

    getLeagueStandings({ dispatch, commit }, id) {
        commit("setLeagueLoading", true);
        leagueService.getLeagueStandings(id).then(response => {
            commit("setLeagueStandings", response.data);
            commit("setLeagueLoading", false);
        });
    },

    getLeagueMatches({ dispatch, commit }, id) {
        commit("setLeagueLoading", true);
        leagueService.getLeagueMatches(id).then(response => {
            commit("setLeagueMatches", response.data);
            commit("setLeagueLoading", false);
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
