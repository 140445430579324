<template>
    <div class="bg-white px-4 py-5 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{title}}
        </h3>
    </div>
</template>

<script>
export default {
    name: "CardHeading",
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
