<template>
    <div v-show="isActive">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        name: { required: true },
        selected: { default: false },
        icon: { required: false }
    },

    data() {
        return {
            isActive: false
        };
    },

    computed: {
        href() {
            return '#' + this.name.toLowerCase().replace(/ /g, '-');
        }
    },

    mounted() {
        if (localStorage.selectedTab == null) {
            localStorage.selectedTab = "#deposit-logs";
        }

        if (localStorage.selectedTab == this.href) {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
    },
}
</script>
