<template>
    <div class="w-full px-8 py-4 bg-blue-700 rounded-lg shadow-xl md:sticky md:top-5">
        <div class="relative">
            <div class="w-full mb-4">
                <label for="status" class="block mb-2 text-xs font-bold tracking-wide text-gray-500 uppercase">
                    Status
                </label>
                <select id="status" name="status"
                    class="block w-full px-4 py-3 pr-8 leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded appearance-none focus:outline-none focus:border-blue-500">
                    <option value="">All</option>
                    <option value="1">Upcoming</option>
                    <option value="2">Active</option>
                    <option value="3">Past</option>
                </select>
            </div>
            <div class="w-full mb-4">
                <label for="regions" class="block mb-2 text-xs font-bold tracking-wide text-gray-500 uppercase">
                    Regions
                </label>
                <select id="regions" name="regions"
                    class="block w-full px-4 py-3 pr-8 leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded appearance-none focus:outline-none focus:border-blue-500">
                    <option value="">All</option>
                    <option value="1">Global</option>
                    <option value="2">Europe</option>
                </select>
            </div>
            <div class="w-full mb-4">
                <label for="entry_fee" class="block mb-2 text-xs font-bold tracking-wide text-gray-500 uppercase">
                    Entry Fee
                </label>
                <select id="entry_fee" name="entry_fee"
                    class="block w-full px-4 py-3 pr-8 leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded appearance-none focus:outline-none focus:border-blue-500">
                    <option value="">Any</option>
                    <option value="">Paid</option>
                    <option value="">Free</option>
                </select>
            </div>
            <!-- <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                </svg>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "LeagueFilters"

}
</script>

<style scoped></style>
