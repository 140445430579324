<template>
  <div v-if="loading">
      <div class="loader"></div>
  </div>
  <div v-else-if="conversation">
      <div class="text-white rounded border border-blue-700 bg-blue-800 shadow-lg">
          <div>
              <div class="text-white rounded border border-blue-700 bg-blue-800">
                <div class="flex text-xs px-6 py-4 items-center">
                    <b class="mr-2 ">In Conversation: </b>
                    <img :src="user.storage_avatar" class="mr-1 w-8 h-8 rounded-full" :title="user.username" :alt="user.username" v-for="user in conversation.users" :key="user.id">
                </div>
              </div>
              <div class="py-2 px-2 flex relative">
                <img class="w-10 h-10 rounded-full mr-3" v-bind:src="conversation.user.storage_avatar" :title="conversation.user.username + ' avatar'" v-bind:alt="conversation.user.username + ' avatar'">
                <div class="relative">
                    <div class="conversation-msg shadow-lg bg-blue-900 rounded text-xs px-2 py-3">{{ conversation.body }}</div>
                </div>
              </div>
              <div class="py-2 px-2 flex relative" v-for="reply in conversation.replies" :key="reply.id">
                <img class="w-10 h-10 rounded-full mr-3" v-bind:src="reply.user.storage_avatar" :title="reply.user.username + ' avatar'" v-bind:alt="reply.user.username + ' avatar'">
                <div class="relative">
                    <div class="conversation-msg shadow-lg bg-blue-900 rounded text-xs px-2 py-3">{{ reply.body }}</div>
                </div>
              </div>
              <div class="text-white rounded border border-blue-700 bg-blue-900 py-2 px-4">
                  <conversation-reply-form></conversation-reply-form>
              </div>
          </div>
      </div>
  </div>
  <div v-else>
      Select a conversation
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: {
        'id': {
            default: null
        }
    },
    computed: mapGetters({
        conversation: 'currentConversation',
        loading: 'loadingConversation'
    }),
    methods: {
        ...mapActions([
            'getConversation'
        ]),
    },
    mounted() {
        if (this.id !== null) {
            this.getConversation(this.id)
        }
    }
}
</script>

<style>

</style>
