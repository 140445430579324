<template>
<div>
    <div class="relative group">
        <div @click.prevent="toggleDropdown" href="#" class="flex items-center mx-3 text-sm font-semibold tracking-wide text-white cursor-pointer">
            <div><img :src="user.storage_avatar" class="object-cover w-10 h-10 mr-3 rounded-full" :alt="user.username"></div>
            <div class="leading-tight">
                <div>{{ user.username }}</div>
                <div class="text-blue-300 text-xxs uppercase" v-text="getTitleText()"></div>
            </div>
            <div class="ml-2 text-xs text-gray-600">
                <i class="fas fa-angle-down"></i>
            </div>
        </div>
        <div class="hidden opacity-0 group-hover:block group-hover:opacity-100 transition-all duration-1000 ease-out h-auto absolute z-100 pt-3 text-sm font-medium text-white bg-blue-600 border border-blue-700 rounded-md shadow-lg sm:w-48 -right-1">
            <a :href="'/user/profile/' + user.slug" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-user"></i>Profile</a>
            <a href="/user/teams" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-users"></i>Teams</a>
            <a href="/user/friends" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-user-friends"></i>Friends</a>
            <a href="/team/invites" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-envelope-open-text"></i>Team Invites</a>
            <!-- <a href="/user/prizes" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-gifts"></i>Prizes</a> -->
            <a href="/match-finder" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-gamepad"></i>Match Finder</a>
            <a href="/challenges" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-gamepad"></i>Challenge Center</a>
            <a href="/tickets" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-life-ring"></i>Support</a>
            <a href="/user/settings" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-user-shield"></i>Settings</a>
            <a href="/bank" class="block px-4 py-2 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-piggy-bank"></i>Bank</a>
            <a class="block px-4 py-2 hover:bg-blue-800 hover:text-white" href="/support-a-creator"><i class="mr-2 text-blue-500 fas fa-video"></i>Support A Creator</a>
            <a class="block px-4 py-2 hover:bg-blue-800 hover:text-white" v-show="user.affiliate" href="/creator-center"><i class="mr-2 text-blue-500 fas fa-video"></i>Creator Center</a>
            <a class="block px-4 py-2 hover:bg-blue-800 hover:text-white" v-show="isAdmin" href="/nova"><i class="mr-2 text-blue-500 fas fa-cog"></i>Nova</a>
            <a class="block px-4 py-2 border-t border-gray-700 md:hidden hover:bg-blue-800 hover:text-white" href="#"><i class="mr-2 text-blue-500 fas fa-coins"></i>{{ user.credits }}</a>
            <a class="block px-4 py-2 border-b border-gray-700 md:hidden hover:bg-blue-800 hover:text-white" href="#"><i class="mr-2 text-blue-500 fas fa-dollar-sign"></i>{{ user.balance }}</a>
            <a href="#" @click.prevent="logout" class="block px-4 py-2 border-t border-gray-800 hover:bg-blue-800 hover:text-white"><i class="mr-2 text-blue-500 fas fa-power-off"></i>Logout</a>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'account-dropdown',
    props: ['user'],
    data() {
        return {
            dropdownOpen: false,
            isAdmin: false,
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        logout() {
            axios.post('/logout')
                .then(() => {
                    window.location.href = "/login";
                })
                .catch(error => {
                    window.location.href = "/login";
                });
        },
        getTitleText()
        {
            if(this.user.elite_membership === 1 || this.user.elite_membership?.expired === 0) {
                return 'Elite Member'
            } else {
                return 'Member'
            }
        }
    }
}
</script>
