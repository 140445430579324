<template>
    <div class="w-full flex">
        <div class="w-2/6 px-4 -mx-4">
            <conversations></conversations>
        </div>
        <div class="w-4/6 px-4 ">
            <conversation :id="id"></conversation>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        'id': {
            default: null
        }
    }
}
</script>

<style>

</style>