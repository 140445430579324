export default {
    getLeagues() {
        return new Promise((resolve, reject) => {
            axios.get("/api/leagues/").then(response => {
                resolve(response);
            });
        });
    },

    getLeague(id) {
        return new Promise((resolve, reject) => {
            axios.get("/api/leagues/" + id).then(response => {
                resolve(response);
            });
        });
    },

    getLeagueStandings(id) {
        return new Promise((resolve, reject) => {
            axios.get("/api/leagues/" + id + "/standings").then(response => {
                resolve(response);
            });
        });
    },

    getLeagueMatches(id) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/leagues/${id}/matches`).then(response => {
                resolve(response);
            });
        });
    }
};
