import leagueService from './../api/league'

const state = {
    leagues: [],
    loadingLeagues: true
}

const getters = {
    allLeagues: state => {
        return state.leagues;
    },
    loadingLeagues: state => {
        return state.loadingLeagues;
    },

    freeLeagues: state => {
        return state.leagues.filter(league => league.entry_fee == 0)
    }
}

const mutations = {
    setLeagues(state, leagues) {
        state.leagues = leagues;
    },
    setLeaguesLoading(state, loadingState) {
        state.loadingLeagues = loadingState;
    }
}

const actions = {
    getLeagues({dispatch, commit}, page) {
        commit('setLeaguesLoading', true);

        leagueService.getLeagues(1).then((response) => {
            commit('setLeagues', response.data.data);
            commit('setLeaguesLoading', false);
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
