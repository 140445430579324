<template>
    <div>
        <div class="flex flex-col gap-y-4 md:flex-row">
            <div class="w-full px-4 lg:w-1/4 md:order-2">
                <LeagueFilters></LeagueFilters>
            </div>
            <div class="flex flex-wrap w-full gap-y-4 lg:w-3/4">
                <div v-if="loading" class="loader"></div>
                <div class="px-4 lg:w-1/3" v-else v-for="league in leagues" :key="league.id">
                    <league-card :league="league"></league-card>
                </div>
                <div class="px-4 text-lg my-5" v-if="leagues.length === 0">
                    Keep an eye out - a League will be posted soon!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeagueFilters from "./LeagueFilters.vue";
import LeagueCard from "./LeagueCard.vue";
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "LeaguesDashboard",
    components: {
        LeagueFilters,
        LeagueCard,
    },
    computed: {
        ...mapGetters({
            leagues: 'allLeagues',
            loading: 'loadingLeagues'
        }),
    },
    methods: {
        ...mapActions([
            'getLeagues',
        ]),

    },
    mounted() {
        this.getLeagues(1);
    }
}
</script>
