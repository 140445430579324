<template>
    <div class="w-full">
        <league-register-team-modal></league-register-team-modal>
        <div v-if="loading" class="loader"></div>
        <div v-else-if="league">
            <div id="bg" class="-mt-12 game-header-img z-1" :style="{
                background:
                    'url(\'' + league.game.storage_header_url + '\')'
            }"></div>
            <div id="main" class="py-24 text-white">
                <div class="container mx-auto">
                    <div id="breadcrumbs" class="mx-6 mb-1 text-xs">
                        Home
                        <span class="ml-1 mr-1 font-extrabold text-gray-600">></span>
                        Leagues
                        <span class="ml-1 mr-1 font-extrabold text-gray-600">></span>
                        <span class="ml-1 mr-1 text-gray-600">{{
                            league.name
                        }}</span>
                    </div>

                    <div id="heading-area"
                        class="flex flex-col items-center justify-between mx-6 md:mx-2 sm:flex-row sm:mx-0">
                        <div class="flex md:w-3/4">
                            <div
                                class="flex items-center self-center hidden px-2 py-2 mr-2 bg-blue-900 rounded-full sm:block">
                                <img :src="`${league.game.storage_icon_url}`" class="w-8 h-8" alt="" />
                            </div>
                            <div class="flex flex-col text-4xl font-extrabold lg:flex-row lg:items-center">
                                <div class="inline-block">
                                    {{ league.name }}
                                    <span class="text-gray-600">{{ league.max_team_size }} v
                                        {{ league.max_team_size }}</span>
                                    <span
                                        class="hidden px-3 py-1 ml-4 font-semibold text-center text-white uppercase rounded-lg lg:inline text-xxs"
                                        :class="league.platform.color">
                                        <i class="mr-1" :class="[league.platform.icon]"></i>
                                        {{ league.platform.name }}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        class="px-3 py-1 font-semibold text-center text-white uppercase rounded-lg lg:hidden text-xxs"
                                        :class="league.platform.color">
                                        <i class="mr-1" :class="[league.platform.icon]"></i>
                                        {{ league.platform.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--
                        <span v-if="signedIn">
                            <league-register-button :price="league.entry_fee"></league-register-button>
                        </span>
                        -->
                    </div>
                </div>

                <div class="flex flex-col mb-10 bg-blue-800 border border-gray-800 md:pt-4 md:mt-5 sm:flex-row">
                    <div class="container flex flex-col justify-between px-4 py-4 mx-auto lg:flex-row gap-y-4">
                        <div class="flex flex-col justify-around sm:flex-row">
                            <div id="stat-box"
                                class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full ">
                                <i class="mr-4 text-purple-600 fa-solid fa-trophy"></i>
                                <div>
                                    <div class="text-xs font-bold">
                                        $ {{ league.prize_amount }}
                                    </div>
                                    <div class="text-xs text-gray-600">
                                        Prize Awarded
                                    </div>
                                </div>
                            </div>
                            <div id="start-date-stat-box"
                                class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                                <i class="mr-4 text-blue-500 fas fa-clock"></i>
                                <div>
                                    <div class="text-xs font-bold">
                                        {{ league.start_date }} GMT
                                    </div>
                                    <div class="text-xs text-gray-600">
                                        League Start
                                    </div>
                                </div>
                            </div>
                            <div id="end-date-stat-box"
                                class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                                <i class="mr-4 text-green-bright fas fa-calendar-check"></i>
                                <div>
                                    <div class="text-xs font-bold">
                                        {{ league.end_date }} GMT
                                    </div>
                                    <div class="text-xs text-gray-600">
                                        League End
                                    </div>
                                </div>
                            </div>
                            <div id="region-stat-box"
                                class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                                <i class="mr-4 text-orange-500 fas fa-globe"></i>
                                <div>
                                    <div class="text-xs font-bold">
                                        {{ league.region.name }}
                                    </div>
                                    <div class="text-xs text-gray-600">
                                        League Region
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center px-8 mt-4 lg:px-0 sm:mt-0">
                            <div>
                                <div class="text-xs font-bold">
                                    Teams Registered
                                </div>
                                <div class="text-2xl font-semibold text-center">
                                    <span class="text-blue-500">{{
                                        league.teams.length
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mx-auto mt-5 mb-1">
                    <div class="flex flex-wrap px-4">
                        <div class="w-full">
                            <div class="px-6 text-white bg-blue-600 border border-gray-800 rounded-lg">
                                <div class="items-center justify-between text-xs">
                                    <!-- Tab area -->
                                    <tabs>
                                        <tab icon="fas fa-info-circle mr-1 " name="Info" :selected="true">
                                            <div id="main-area" class="flex w-full px-0 py-4">
                                                <div class="flex flex-wrap w-full">

                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                                        <div class="-mb-8 text-4xl font-bold">
                                                            1st
                                                        </div>
                                                        <img src="../../../assets/gold-trophy.svg" class="w-32 h-32">
                                                        <div class="-mt-8 text-xl font-bold text-gray-400">
                                                            ${{ league.prize_amount }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                                        <div class="-mb-8 text-4xl font-bold">
                                                            2nd
                                                        </div>
                                                        <img src="../../../assets/silver-trophy.svg" class="w-32 h-32">
                                                        <div class="-mt-8 text-xl font-bold text-gray-400">
                                                            ${{ league.second_place }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                                        <div class="-mb-8 text-4xl font-bold">
                                                            3rd
                                                        </div>
                                                        <img src="../../../assets/bronze-trophy.svg" class="w-32 h-32">
                                                        <div class="-mt-8 text-xl font-bold text-gray-400">
                                                            ${{ league.third_place }}
                                                        </div>
                                                    </div>



                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12 ">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-globe-europe"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Region
                                                        </div>
                                                        <div class="text-lg">
                                                            {{
                                                                league.region
                                                                    .name
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-gamepad"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Game
                                                        </div>
                                                        <div class="text-lg">
                                                            {{
                                                                league.game.name
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-coins"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Prize Pool
                                                        </div>
                                                        <div class="text-lg">
                                                            ${{
                                                                league.prize_amount
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-stopwatch"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            League Start
                                                        </div>
                                                        <div class="text-lg">
                                                            {{
                                                                league.start_date
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-user-ninja"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Format
                                                        </div>
                                                        <div class="text-lg">
                                                            {{ league.format }}
                                                            v
                                                            {{ league.format }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-coins"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Entry Fee
                                                        </div>
                                                        <div class="text-lg">
                                                            {{
                                                                league.entry_fee
                                                            }}
                                                            Credits
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i class="text-gray-500 fas fa-hand-rock"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Best Of
                                                        </div>
                                                        <div class="text-lg">
                                                            {{ league.best_of ? league.best_of : 'N/A' }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500">
                                                            <i :class="league
                                                                .platform
                                                                .icon
                                                                " class="text-gray-500"></i>
                                                        </div>
                                                        <div class="text-xxs">
                                                            Platform
                                                        </div>
                                                        <div class="text-lg">
                                                            {{
                                                                league.platform
                                                                    .name
                                                            }}
                                                        </div>
                                                    </div>

                                                    <div v-if="league.crossplay_options
                                                            ">
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500">
                                                                <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                                            </div>
                                                            <div class="text-xxs">
                                                                PC Players
                                                            </div>
                                                            <div class="text-lg">
                                                                {{
                                                                    league.pc_players
                                                                    ? "Allowed"
                                                                    : "Disallowed"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500">
                                                                <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                                            </div>
                                                            <div class="text-xxs">
                                                                Input Type
                                                            </div>
                                                            <div class="text-lg">
                                                                {{
                                                                    league.input_option
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500">
                                                                <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                                            </div>
                                                            <div class="text-xxs">
                                                                PC Stream
                                                                Required
                                                            </div>
                                                            <div class="text-lg">
                                                                {{
                                                                    league.pc_stream_required
                                                                    ? "Yes"
                                                                    : "No"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500">
                                                                <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                                            </div>
                                                            <div class="text-xxs">
                                                                Monitorcam Required
                                                            </div>
                                                            <div class="text-lg">
                                                                {{
                                                                    league.moss_required
                                                                    ? "Yes"
                                                                    : "No"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>
                                        <tab icon="fas fa-clipboard-list mr-1" name="Rules">
                                            <div id="main" class="px-4 py-4">
                                                <div id="rules">
                                                    <div v-html="league.ruleset
                                                                .content
                                                            "></div>
                                                </div>
                                            </div>
                                        </tab>
                                        <tab icon="fas fa-award mr-1" name="Standings">
                                            <div id="standings-tab" class="py-8">
                                                <div id="standings" class="flex flex-wrap gap-y-8">
                                                    <div v-for="group in leagueStandings" class="w-full px-4 md:w-4/12">
                                                        <div class="mb-2 text-lg font-bold">
                                                            Group -
                                                            <span>{{
                                                                group.name
                                                            }}</span>
                                                        </div>
                                                        <div class="flex flex-wrap w-full">
                                                            <div class="w-full overflow-x-auto">
                                                                <div class="w-full text-xs text-white rounded">
                                                                    <table
                                                                        class="w-full bg-blue-800 border border-blue-700">
                                                                        <thead>
                                                                            <tr
                                                                                class="font-semibold text-gray-600 bg-gray-900 text-xxs">
                                                                                <th
                                                                                    class="px-6 pt-2 pb-2 text-left border-r border-blue-700">
                                                                                    Team
                                                                                </th>
                                                                                <th
                                                                                    class="px-6 pt-2 pb-2 text-center border-r border-blue-700">
                                                                                    Wins
                                                                                </th>
                                                                                <th class="px-6 pt-2 pb-2 text-center">
                                                                                    Losses
                                                                                </th>
                                                                                <th class="px-6 pt-2 pb-2 text-center">
                                                                                    Points
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="font-extrabold text-white text-xxs">
                                                                            <tr class="text-center"
                                                                                v-for="team in group.teams">
                                                                                <td class="flex items-center px-2 py-3 ">
                                                                                    <a :href="'/team/show/' + team.id">{{
                                                                                        team.name }}</a>
                                                                                </td>
                                                                                <td class="px-2">
                                                                                    {{
                                                                                        team.wins
                                                                                    }}
                                                                                </td>
                                                                                <td class="px-2">
                                                                                    {{
                                                                                        team.losses
                                                                                    }}
                                                                                </td>
                                                                                <td class="px-2">
                                                                                    {{
                                                                                        team.points
                                                                                    }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>
                                        <tab icon="fas fa-gamepad mr-1" name="Matches">
                                            <div id="matches-tab" class="py-4">
                                                <div id="matches"
                                                    class="md:grid md:grid-cols-3 xl:grid-cols-4 md:gap-x-4 md:gap-y-2">
                                                    <div v-for="match in leagueMatches"
                                                        class="overflow-x-auto md:col-span-1">
                                                        <table class="w-max">
                                                            <thead>
                                                                <tr
                                                                    class="font-semibold text-left text-gray-600 bg-gray-900 text-xxs">
                                                                    <th
                                                                        class="px-4 py-2 text-left border-r border-blue-700 ">
                                                                        Team One
                                                                    </th>
                                                                    <th
                                                                        class="px-4 py-2 text-left border-r border-blue-700 ">
                                                                        Team Two
                                                                    </th>
                                                                    <th
                                                                        class="px-4 py-2 text-left border-r border-blue-700 ">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="font-extrabold text-white text-xxs">
                                                                <tr>
                                                                    <td class="px-4 bg-blue-800 border-r border-blue-700">
                                                                        <div class="flex items-center py-3">
                                                                            <img class="w-8 h-8 mr-3 rounded-full"
                                                                                :src="match.teamOneAvatar ? match.teamOneAvatar : '/images/default-avatar.png'"
                                                                                alt="">
                                                                            {{ match.teamOneName }}
                                                                        </div>
                                                                    </td>
                                                                    <td class="px-4 bg-blue-800 border-r border-blue-700">
                                                                        <div class="flex items-center py-3">
                                                                            <img class="w-8 h-8 mr-3 rounded-full"
                                                                                :src="match.teamOneAvatar ? match.teamOneAvatar : '/images/default-avatar.png'"
                                                                                alt="">
                                                                            {{ match.teamTwoName }}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        class="px-2 text-blue-500 bg-blue-800 border-r border-blue-700">
                                                                        <a
                                                                            :href="`/leagues/${league.id}/matches/${match.id}`">
                                                                            View Match
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>
                                    </tabs>
                                    <!-- End tabs -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tabs from "../partials/Tabs.vue";
import Tab from "../partials/Tab.vue";
import LeagueRegisterButton from "./LeagueRegisterButton.vue";
import LeagueRegisterTeamModal from "./LeagueRegisterTeamModal.vue";

export default {
    name: "LeagueDashboard",
    props: ["leagueId"],
    components: {
        Tabs,
        Tab,
        LeagueRegisterButton,
        LeagueRegisterTeamModal
    },
    computed: {
        ...mapGetters({
            league: "league",
            leagueStandings: "leagueStandings",
            leagueMatches: "leagueMatches",
            loading: "loadingLeague"
        })
    },
    methods: {
        ...mapActions(["getLeague", "getLeagueStandings", "getLeagueMatches"])
    },
    mounted() {
        this.getLeague(this.leagueId);
        this.getLeagueStandings(this.leagueId);
        this.getLeagueMatches(this.leagueId);
    }
};
</script>
