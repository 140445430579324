<template>
    <modal name="team-create-modal" classes="bg-blue-700 rounded-lg border-gray-800 border text-white" height="auto" width="22%">
        <div class="py-6 px-12">
            <div class="text-center mb-4">
                <div class="text-lg font-bold uppercase">
                    Create Team
                </div>
                <div class="text-xs text-gray-600">
                    Setup your team to enter the battlefield
                </div>
            </div>
            <form @submit.prevent="createTeam">
                <div class="mb-3 mt-3">
                    <span role="alert" class="text-red-500 text-xs" v-text="errors.team_name[0]" v-if="errors.team_name">
                    </span>
                    <input @keydown="delete errors.team_name"
                           spellcheck="false"
                           v-model="message.team_name"
                           type="text"
                           placeholder="Team Name"
                           class="mt-1 block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                    >
                </div>
                <div class="mb-3 mt-4">
                    <label class="custom-label flex">
                        <div class="bg-blue-900 w-5 h-4 flex justify-center items-center mr-1 rounded">
                            <input type="checkbox" name="terms" class="hidden" v-model="message.termsChecked">
                            <svg viewBox="0 0 172 172" class="hidden w-4 h-4 text-green-600 pointer-events-none bg-blue-500 rounded">
                                <g fill="none" stroke-width="none" stroke-miterlimit="10" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal;">
                                    <path d="M0 172V0h172v172z"></path>
                                    <path d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z" fill="white" stroke-width="2"></path>
                                </g>
                            </svg>
                        </div> <span class="text-gray-600 text-xs font-semibold"> By checking here you agree to the <a href="http://rise-up.test/terms" class="underline text-blue-500">Tournament Rules</a></span>
                    </label>
                </div>
                <div class="mt-6">
                    <button type="submit" :disabled="!showActiveRegisterButton()" :class="{'opacity-50 cursor-not-allowed': !showActiveRegisterButton()}" class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                        Lets Go
                    </button>
                </div>
            </form>
        </div>
    </modal>
</template>
<script>
export default {
    props: ['tournament'],
    data() {
        return {
            message: {
                team_name: "",
                termsChecked: false,
                tournament_id: this.tournament.id,
                game_id: this.tournament.game_id
            },
            errors: {}
        }
    },

    methods: {
        showActiveRegisterButton()
        {
            return this.message.team_name.length > 0 && this.message.termsChecked
        },
        createTeam() {
            axios.post('/api/teams', this.message)
                .then(({data}) => {
                    this.$modal.hide('team-create-modal')
                    window.location.replace("/teams/" + data.data.id);
                })
                .catch(errors => {
                    this.errors = errors.response.data.errors
                })
        }
    }
}
</script>
