<template>
    <div class="w-full rounded-lg">
        <div class="border-b border-gray-800 tabs tabs-background-color">
            <ul class="flex">
                <li v-for="tab in tabs" :key="tab.name" class="px-3 py-4 mr-4"
                    :class="{ 'border-blue-500 border-b-2': tab.isActive }" @click="selectTab(tab)">
                    <a class="font-semibold" :class="{ 'text-gray-600': !tab.isActive }" :href="tab.href"
                        @click="selectTab(tab)"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return { tabs: [] };
    },

    created() {
        this.tabs = this.$children;
    },

    mounted() {
        if (localStorage.selectedTab) {
            this.selectedTab = localStorage.selectedTab;
        }
    },

    methods: {
        selectTab(selectedTab) {
            localStorage.selectedTab = selectedTab.href;
            this.tabs.forEach(tab => {
                tab.isActive = (tab.href == localStorage.selectedTab);
            });
        }
    }
}
</script>
<style scoped>
li {
    cursor: pointer;
}
</style>
