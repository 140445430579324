<template>
    <a :href="'/leagues/' + league.id"
        class="block h-full mb-2 overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1">
        <div class="relative flex flex-col justify-between h-full text-white bg-blue-800 border border-blue-700 rounded">
            <div class="relative px-2 pt-2 pb-4">
                <img :src="`${league.game.storage_background_url}`" class="mb-5 rounded-lg" />
                <div class="px-4">
                    <div class="flex justify-between mb-1 font-bold text-gray-600 uppercase text-xxs">
                        <div>{{ league.start_date }}</div>
                        <div class="flex"><span class="ml-3">{{ league.region.short_name }}</span></div>
                    </div>
                    <div class="flex mb-3">
                        <div class="flex items-center px-1 mr-2 text-xs rounded text-green-bright"><i aria-hidden="true"
                                class="fa-solid fa-shuffle"></i></div>
                        <div class="text-sm font-semibold text-white">{{ league.name }}</div>
                    </div>
                    <div class="flex flex-wrap justify-between gap-y-2">
                        <div>
                            <div class="mb-1 font-bold text-gray-600 text-xxs">Prize Pool</div>
                            <div class="text-xs font-bold"><i aria-hidden="true"
                                    class="mr-1 text-orange-500 fa-solid fa-trophy"></i> $
                                {{ league.prize_amount }}</div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-600 text-xxs">Team Size</div>
                            <div class="text-xs font-bold"><i aria-hidden="true"
                                    class="mr-1 text-indigo-600 fa-solid fa-dice"></i> {{ league.max_team_size }} v {{
                                        league.max_team_size }}</div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-600 text-xxs">Entry Fee</div>
                            <div class="text-xs font-bold"><i aria-hidden="true"
                                    class="mr-1 text-green-500 fa-solid fa-coins"></i> {{ league.entry_fee }} Credits</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row px-4 py-2 bg-blue-700 rounded" style="min-height: 56px;">
                <div class="flex"></div>
                <div class="flex items-center ml-8 font-bold text-gray-600 text-xxs">{{ league.teams.length }} Registered
                </div>
            </div>
        </div>
    </a>

    <!--    <a :href="'/leagues/' + league.id" class="flex w-full px-8 py-4 mb-4 bg-blue-700 rounded-lg shadow-xl">-->
    <!--        <div class="font-semibold text-white">-->
    <!--            <div>League name: {{ league.name }}</div>-->
    <!--            <div>League game: {{ league.game.name }}</div>-->
    <!--            <div>League Platform: {{ league.platform.name }}</div>-->
    <!--            <div>Entry Fee: {{ league.entry_fee }}</div>-->
    <!--            <div>Prize Amount: {{ league.prize_amount }}</div>-->
    <!--            <div>Ruleset: {{ league.ruleset.name }}</div>-->
    <!--        </div>-->
    <!--    </a>-->
</template>

<script>
export default {
    name: "LeagueCard",
    props: ["league"]
}
</script>

<style scoped></style>
