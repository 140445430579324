<template>
<div class="flex flex-wrap items-stretch w-full relative">
    <form class="flex w-full" action="#" @submit.prevent="reply">
        <textarea v-model="body" class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-800 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 "></textarea>
        <button type="submit" class="bg-blue-500 px-4 h-auto ml-2 rounded-lg"><i class="fas fa-paper-plane"></i></button>
    </form>
</div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data () {
        return {
            body: null
        }
    },
    computed: mapGetters({
        conversation: 'currentConversation'
    }),
    methods: {
        ...mapActions([
            'createConversationReply'
        ]),
        reply() {
            this.createConversationReply({
                id: this.conversation.id,
                body: this.body
            }).then(() => {
                this.body = null
            });
        }
    }
}
</script>

<style>

</style>