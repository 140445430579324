<template>
    <div>
        <div class="text-white rounded border border-blue-700 bg-blue-800 shadow-lg">
            <div class="flex justify-between text-xs px-6 py-4 items-center border-b border-gray-800">
                <h2 class="font-semibold">My Conversations</h2>
            </div>
        </div>
        <div id="convo-list" class=" bg-blue-800 rounded border border-blue-700">
            <div v-if="loading" class="loader"></div>
            <div class="py-2 px-6 border-b border-blue-600" v-for="conversation in conversations" :key="conversation.id" v-else-if="conversations.length">
                <a href="#" @click.prevent="getConversation(conversation.id)" class="text-blue-500 text-xs font-semibold">{{ truncate(conversation.body, 5) }}</a>
                <div class="text-gray-600 text-xxs font-semibold">You and {{ conversation.participant_count }} {{ pluralize('other', conversation.participant_count) }}</div>
                <div class="flex items-center">
                    <img v-bind:src="user.storage_avatar" v-bind:alt="user.username" v-bind:title="user.username" v-for="user in conversation.users" :key="user.id" class="w-6 h-6 rounded-full mr-1" />
                </div>
                <div class="text-gray-600 text-xxs font-semibold">Last reply {{ conversation.last_reply }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import truncate from '../helpers/truncator'
import pluralize from 'pluralize'
import { mapActions, mapGetters } from 'vuex'

export default {
    computed: mapGetters({
        conversations: 'allConversations',
        loading: 'loadingConversations'
    }),
    methods: {
        ...mapActions([
            'getConversations',
            'getConversation'
        ]),
        truncate: truncate,
        pluralize: pluralize
    },
    mounted() {
        this.getConversations(1)
    }
}
</script>

<style>

</style>
