/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";
import {createApp} from "vue/dist/vue.esm-bundler";

// Vue.prototype.signedIn = window.App.signedIn;
// var eventHub = new Vue();
// window.eventHub = eventHub;
// import VModal from "vue-js-modal";
// Vue.use(VModal, { dynamic: true });

// import Vuex from "vuex";
// Vue.use(Vuex);

// import VTooltip from "v-tooltip";
// Vue.use(VTooltip);

// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
// Vue.component("DatePicker", DatePicker);

import store from "./store/index";

import Form from "./helpers/form";
window.Form = Form;

import AccountDropdown from "./components/AccountDropdown.vue";
import GamePlatformFilter from "./components/GamePlatformFilter.vue";
import TicketCreateButton from "./components/TicketCreateButton.vue";
import TicketCreateModal from "./components/TicketCreateModal.vue";
import Tab from "./components/partials/Tab.vue";
import Tabs from "./components/partials/Tabs.vue";
import BankTab from "./components/partials/bank/BankTab.vue";
import BankTabs from "./components/partials/bank/BankTabs.vue";
import TeamCreateModal from "./components/TeamCreateModal.vue";
import TournamentRegisterButton from "./components/TournamentRegisterButton.vue";
import PlayerSearch from "./components/PlayerSearch.vue";
import TeamInviteList from "./components/TeamInviteList.vue";
import TournamentTeamSelectionModal from "./components/TournamentTeamSelectionModal.vue";
import ConversationsDashboard from "./components/ConversationsDashboard.vue";
import DepositModal from "./components/DepositModal.vue";
import DepositButton from "./components/DepositButton.vue";
import WagerCreationForm from "./components/WagerCreationForm.vue";
import WagerChallengeChatContainer from "./components/WagerChallengeChatContainer.vue";
import TicketCreateForm from "./components/TicketCreateForm.vue";
import Modal from "./components/Modal.vue";
import RosterSelection from "./components/RosterSelection.vue";
import NavMobileMenuDropdown from "./components/NavMobileMenuDropdown.vue";
import TournamentMatchChatContainer from "./components/TournamentMatchChatContainer.vue";
import LeaguesDashboard from "./components/Leagues/LeaguesDashboard.vue";
import LeagueDashboard from "./components/Leagues/LeagueDashboard.vue";
import CardHeading from "./components/CardHeading.vue";
import GroupTeamsList from "./components/LeagueAdmin/GroupTeamsList.vue";
import Conversations from "./components/Conversations.vue";
import ConversationReplyForm from "./components/ConversationReplyForm.vue";
import Conversation from "./components/Conversation.vue";
import Flash from "./components/Flash.vue";

window.flash = function(message, level = "success") {
    window.eventHub.$emit("flash", { message, level });
};

const app = createApp({
    el: "#app",
    // components: {
    //     AccountDropdown,
    //     GamePlatformFilter,
    //     TicketCreateButton,
    //     TicketCreateModal,
    //     Tab,
    //     Tabs,
    //     BankTab,
    //     BankTabs,
    //     TeamCreateModal,
    //     TournamentRegisterButton,
    //     PlayerSearch,
    //     TeamInviteList,
    //     TournamentTeamSelectionModal,
    //     ConversationsDashboard,
    //     DepositModal,
    //     DepositButton,
    //     WagerCreationForm,
    //     WagerChallengeChatContainer,
    //     TicketCreateForm,
    //     Modal,
    //     RosterSelection,
    //     NavMobileMenuDropdown,
    //     TournamentMatchChatContainer,
    //     LeaguesDashboard,
    //     LeagueDashboard,
    //     CardHeading,
    //     GroupTeamsList
    // }
});

app.use(store);

app.component("leagues-dashboard", LeaguesDashboard);

app.component("conversations", Conversations);
app.component("conversation-reply-form", ConversationReplyForm);
app.component("conversation", Conversation);
app.component("flash", Flash);

app.component("banktabs", BankTabs);
app.component("banktab", BankTab);

app.component("tabs", Tabs);
app.component("tab", Tab);

app.component('account-dropdown', AccountDropdown)
app.component('game-platform-filter', GamePlatformFilter)
app.component('ticket-create-button', TicketCreateButton)
app.component('ticket-create-modal', TicketCreateModal)
// app.component('tab', Tab)
// app.component('tabs', Tabs)
// app.component('bank-tab', BankTab)
// app.component('bank-tabs', BankTabs)
app.component('team-create-modal', TeamCreateModal)
app.component('tournament-register-button', TournamentRegisterButton)
app.component('player-search', PlayerSearch)
app.component('team-invite-list', TeamInviteList)
app.component('tournament-team-selection-modal', TournamentTeamSelectionModal)
app.component('conversations-dashboard', ConversationsDashboard)
app.component('deposit-modal', DepositModal)
app.component('deposit-button', DepositButton)
app.component('wager-creation-form', WagerCreationForm)
app.component('wager-challenge-chat-container', WagerChallengeChatContainer)
app.component('ticket-create-form', TicketCreateForm)
app.component('modal', Modal)
app.component('roster-selection', RosterSelection)
app.component('nav-mobile-menu-dropdown', NavMobileMenuDropdown)
app.component('tournament-match-chat-container', TournamentMatchChatContainer)
// app.component('leagues-dashboard', LeaguesDashboard)
app.component('league-dashboard', LeagueDashboard)
app.component('card-heading', CardHeading)
app.component('group-teams-list', GroupTeamsList)

app.mount('#app');

// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// init Swiper:
const swiper = new Swiper(".swiper", {
    // configure Swiper to use modules
    slidesPerView: 1,
    modules: [Navigation, Pagination, Autoplay],

    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },

    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    }
});

import.meta.glob(["../assets/**"]);

import './appAlpine';

// import Alpine from 'alpinejs';
// window.Alpine = Alpine;
//
// Alpine.start();
