<template>
    <a href="#" @click.prevent="show">
        <div class="btn-gradient-blue w-40 px-4 py-3 rounded-lg font-medium text-xs font-semibold text-center"><i aria-hidden="true" class="text-sm mr-2 fas fa-life-ring"></i>Create A Ticket</div>
    </a>
</template>
<script>
export default {
    methods: {
        show() {
            this.$modal.show('ticket-create-modal');
        }
    }
}
</script>