<template>
    <div>
        <div v-if="Object.keys(form.errors.errors).length > 0"
             class="bg-red-500 border-t-4 border-red-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2 w-6/10"
             role="alert">
            <div class="flex">
                <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
                <div>
                    <p class="font-bold">Error</p>
                    <p class="text-sm" v-text="form.errors.errors[Object.keys(form.errors.errors)[0]][0]"></p>
                </div>
            </div>
        </div>

        <div class="flex">
            <form method="POST" @submit.prevent="submit" @keydown="form.errors.clear($event.target.name)"
                  class="w-full flex flex-col sm:flex-row justify-center">
                <div
                    class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 sm:px-16 py-16 w-full sm:w-6/12 mr-4 order-2 sm:order-1 ">
                    <div id="inner" class="pb-6 border-gray-800 mb-4">

                        <div class="mb-6">
                            <div class="text-3xl font-extrabold mb-1">Challenge Up</div>
                            <div v-if="!challengedTeam" class="text-gray-400 text-sm">
                                Challenge your competitors at {{ game.name }}
                            </div>
                            <div class="text-gray-400 text-sm" v-else>
                                Challenge team {{ challengedTeam.name }} at {{ game.name }}
                            </div>
                        </div>
                        <div v-if="step == 1">
                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Start Time</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.start_time" name="start_time"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option value="now">Available Now</option>
                                        <option v-for="date in dates" :key="date.time" :value="date.date">{{
                                                date.time
                                            }} Minutes
                                        </option>
                                    </select>
                                    <div v-if="form.start_time == 'now'"
                                         class="mt-2 text-gray-400 text-xs font-semibold">
                                        <span class="text-white">Available Now </span> challenge will remain active for
                                        the next 2 hours or until the match is accepted or cancelled. Once accepted it
                                        will be scheduled in 10 minutes.
                                    </div>
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Team</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.team" @change="teamSelectionChanged($event)" name="team_id"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option v-for="team in teams" :key="team.id" :value="team.id">{{
                                                team.name
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Game Mode</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.game_mode_id" name="game_mode_id"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option v-for="gameMode in game.game_modes" :key="gameMode.id"
                                                :value="gameMode.id">{{ gameMode.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Format</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.team_size" name="team_size"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option value="1" selected>1v1</option>
                                        <option value="2">2v2</option>
                                        <option value="3">3v3</option>
                                        <option value="4">4v4</option>
                                        <option value="5">5v5</option>
                                        <option value="6">6v6</option>
                                        <option value="7">7v7</option>
                                        <option value="8">8v8</option>
                                        <option value="9">9v9</option>
                                        <option value="10">10v10</option>
                                        <option value="11">11v11</option>
                                    </select>
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Best Of</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.best_of" name="best_of"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option value="1">Best of 1</option>
                                        <option value="3">Best of 3</option>
                                        <option value="5">Best of 5</option>
                                    </select>
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Platform</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.platform_id" name="platform_id"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option v-for="platform in game.platforms" :key="platform.id"
                                                :value="platform.id">{{ platform.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="text-xs text-gray-400 py-1">Region</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <select v-model="form.region_id" name="region_id"
                                            class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                        <option v-for="region in regions" :key="region.id" :value="region.id">
                                            {{ region.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="text-xs text-gray-400 py-1">Challenge Amount (Per Player)</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <input
                                        v-model="form.challenge_amount"
                                        type="text"
                                        name="challenge_amount"
                                        class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    />
                                </div>
                            </div>

                            <div :disabled="!selectedTeam" @click="next()"
                                 v-bind:class="{'btn-gradient-blue cursor-pointer': selectedTeam, 'cursor-not-allowed': !selectedTeam}"
                                 class="mt-6 px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block bg-gray-800 ">
                                <span>Choose Team</span>
                            </div>
                        </div>
                        <div v-if="step == 2">
                            <div v-if="selectedTeam">
                                <div>
                                    <div class="text-md font-semibold mb-1 text-gray-300">Select Roster</div>
                                    <div class="text-gray-400 text-sm mb-2">
                                        Select {{ form.team_size }} {{ pluralize('player', parseInt(form.team_size)) }} for your
                                        {{ form.team_size }}v{{ form.team_size }} match.
                                    </div>
                                </div>

                                <div class="flex justify-between mb-1 items-center"
                                     v-for="member in selectedTeam.members" :key="member.id">
                                    <div class="flex py-3 px-3  items-center rounded-lg bg-blue-800 mb-1 w-full">
                                        <div>
                                            <div>
                                                <label class="text-gray-500 font-bold">
                                                    <input :id="member.username" :value="member.id"
                                                           v-model="form.selectedRoster" class="mr-2 leading-tight"
                                                           type="checkbox">
                                                </label>
                                            </div>
                                        </div>
                                        <img :src="member.storage_avatar" class="mr-2 rounded-lg w-8 h-8">
                                        <div class="leading-none">
                                            <div class="font-semibold text-xs">{{ member.username }}</div>
                                            <!-- <div v-if="form.platform.name === 'Xbox'">
                                                <span class="text-xxs font-semibold italic" v-if="member.gamingAccounts">{{ member.gamingAccounts.xbl }}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>

                                <div v-if="form.selectedRoster.length > form.team_size" class="border-red-500 border-2 px-2 py-1 rounded-lg">
                                    <div class="text-red-500 text-sm">You can only select {{ form.team_size }} {{ pluralize('player', parseInt(form.team_size)) }} for a {{ form.team_size }}v{{ form.team_size }} match.</div>
                                </div>
                            </div>
                            <div class="flex flex-col sm:flex-row w-full mt-6 ">
                                <div class="px-2 w-full sm:w-1/2">
                                    <button @click="previous()"
                                            class="w-1/2 bg-gray-600 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                        <span>Go Back</span>
                                    </button>
                                </div>
                                <div class="px-2 w-full sm:w-1/2">
                                    <XButton :onSubmit="submit" :loading="isLoading">
                                        Post Challenge
                                    </XButton>
<!--                                    <button type="submit"-->
<!--                                            :disabled="isLoading"-->
<!--                                            class="mt-6 btn-gradient-blue px-4 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">-->
<!--                                        Post Challenge-->
<!--                                    </button>-->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="game.crossplay_options"
                     class="bg-blue-600 text-white rounded-lg border w-full sm:w-4/12 border-gray-800 px-10 pt-8 order-1 sm:order-2 mb-2 sm:mb-0"
                     style="height:fit-content">
                    <div id="inner" class="pb-2 border-gray-800 mb-4">
                        <div class="mb-4">
                            <div class="text-3xl font-extrabold mb-1">Additional Settings</div>
                        </div>
                        <div id="form-group">
                            <div class="text-xs text-gray-400 py-1">PC Players</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.pc_players" name="pc_allowed"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Allowed</option>
                                    <option value="0">Not-Allowed</option>
                                </select>
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="text-xs text-gray-400 py-1">Input Type</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.input_option" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="Any">Any</option>
                                    <option value="Controller">Controller Only</option>
                                    <option value="Mouse & Keyboard">Mouse & Keyboard Only</option>
                                </select>
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="text-xs text-gray-400 py-1">PC Stream Required</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.pc_stream_required" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="text-xs text-gray-400 py-1">Monitorcam Required</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.moss_required" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="game.manages_radar">
                            <div class="text-xs text-gray-400 py-1">Radar Always On</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.radar" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Enabled</option>
                                    <option value="0">Disabled</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="game.manages_snipers">
                            <div class="text-xs text-gray-400 py-1">Snipers</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.snipers" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Allowed</option>
                                    <option value="0">Disallowed</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="game.manages_snaking">
                            <div class="text-xs text-gray-400 py-1">Snaking</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.snaking" name="input_type"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="1">Allowed</option>
                                    <option value="0">Disallowed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import minuteAdder from '../helpers/datetime'
import api from './../store/api/all'
import XButton from "./Button.vue";
import pluralize from "pluralize";

export default {
    props: ['game', 'teams', 'regions', 'actionType', 'challenged-team'],
    components: {
        XButton
    },
    data() {
        return {
            dates: [],
            step: 1,
            isLoading: false,
            selectedTeam: null,
            form: new Form({
                start_time: 'now',
                team_size: '1',
                team: null,
                team_id: null,
                game_mode_id: null,
                best_of: 3,
                platform_id: null,
                region_id: null,
                selectedRoster: [],
                challenge_amount: 0,
                game_id: this.game.id,
                input_option: null,
                pc_players: null,
                pc_stream_required: null,
                radar: 0,
                snipers: 1,
                snaking: 1,
                moss_required: null,
                challenged_team: null,
            })
        }
    },
    mounted() {
        var date = new Date();
        this.dates.push({time: 5, date: new Date(date.getTime() + 5 * 60000)})
        this.dates.push({time: 10, date: new Date(date.getTime() + 10 * 60000)})
        this.dates.push({time: 15, date: new Date(date.getTime() + 15 * 60000)})
        this.dates.push({time: 30, date: new Date(date.getTime() + 30 * 60000)})
        this.dates.push({time: 40, date: new Date(date.getTime() + 40 * 60000)})
        this.dates.push({time: 60, date: new Date(date.getTime() + 60 * 60000)})

        if (this.challengedTeam) {
            this.form.challenged_team = this.challengedTeam.id;
        }

        if (Object.keys(this.teams).length > 0) {
            const firstTeam = this.teams[Object.keys(this.teams)[0]];
            this.form.team = firstTeam.id;
            this.form.team_id = firstTeam.id;
            this.teamSelectionChanged();
        }

        if (this.game.game_modes.length > 0) {
            this.form.game_mode_id = this.game.game_modes[0].id;
        }

        if (this.game.platforms.length > 0) {
            this.form.platform_id = this.game.platforms[0].id;
        }

        if (this.regions.length > 0) {
            this.form.region_id = this.regions[0].id;
        }

    },
    methods: {
        pluralize,
        next() {
            this.step++;
        },
        previous() {
            this.step--;
        },
        submit() {
            this.isLoading = true;

            this.form.submit('post', '/api/challenge').then((resp) => {
                window.location.href = "/match-finder?msg=success";
            }).catch((err) => {
                this.isLoading = false;
            });
        },
        teamSelectionChanged() {
            this.form.selectedRoster = [];
            this.form.team_id = this.form.team;
            api.getTeam(this.form.team).then((resp) => {
                this.selectedTeam = resp.data.data
            });
        },

    }
}
</script>
