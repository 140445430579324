<template>
    <a href="#" class="text-blue-500 text-xs font-semibold" @click.prevent="show">
        Deposit more
    </a>
</template>
<script>
export default {
    methods: {
        show() {
            this.$modal.show('deposit-modal');
        }
    }
}
</script>