<template>
    <a @click.prevent="showModal" href="#" class="inline-flex">
        <div class="btn-gradient-blue bg-blue-500 px-6 py-3 rounded-l-lg font-medium text-xs font-semibold relative">
            <i class="fas fa-check-circle mr-3"></i>Sign Up
            <div class="triangle-3 absolute right-0"></div>
        </div>
        <div class="bg-blue-500 px-5 py-3 rounded-r-lg font-medium text-xs font-semibold z-10">
            {{ price }} {{ pluralize('Credits', price) }}
        </div>
    </a>
</template>

<script>
import pluralize from 'pluralize'

export default {
    name: "LeagueRegisterButton",
    props: ["price"],
    methods: {
        showModal() {
            this.$modal.show('league-team-selection-modal')
        },
        pluralize
    }
}
</script>

<style scoped>

</style>
