<template>
<div class="container mx-auto">
    <div class="flex items-center justify-center w-full h-full">
        <div class="w-full px-4 mb-6 sm:w-6/12">
            <div class="px-6 py-16 text-white bg-blue-600 border border-gray-800 rounded-lg sm:px-16">
                <div id="inner" class="pb-6 mb-4 border-gray-800">
                    <form method="GET" action="" @submit.prevent="submit" @keydown="form.errors.clear($event.target.name)">
                        <div class="mb-6">
                            <div class="mb-1 text-3xl font-extrabold">Create a Ticket</div>
                            <div class="text-sm text-gray-600">
                                Get support from the EsportsHub Team
                            </div>
                        </div>

                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Category</div>
                            <div class="relative flex flex-col items-stretch w-full mb-3">
                                <select  @change="onCategoryChange()" v-model="selectedCategory" name="category" class="block w-full px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white bg-blue-900 border border-gray-800 rounded appearance-none form-select focus:outline-none">
                                    <option v-for="category in categories" :key="category.id" :value="category">{{ category.name }}</option>
                                </select>
                                <div class="mt-1 font-semibold text-red-600 text-xxs" v-text="form.errors.get('category')"></div>
                        </div>
                        </div>
                        <div class="form-group">
                            <div class="py-1 text-xs text-gray-600">Title</div>
                            <div class="relative flex flex-col items-stretch w-full mb-3">
                                <input
                                    v-model="form.title"
                                    type="text"
                                    name="title"
                                    class="flex-1 flex-grow flex-shrink w-px w-full px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500"
                                />
                                <div class="mt-1 font-semibold text-red-600 text-xxs" v-text="form.errors.get('title')"></div>
                            </div>
                        </div>
                        <div v-show="categoryIsDispute">
                            <div class="py-1 text-xs text-gray-600">Team</div>
                            <div class="relative flex flex-col items-stretch w-full mb-3">
                                <select v-model="form.selectedTeam" @change="onTeamChange()" name="selected_team" class="block w-full px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white bg-blue-900 border border-gray-800 rounded appearance-none form-select focus:outline-none">
                                    <option v-for="team in userTeams" :key="team.id" :value="team.id">{{ team.name }}</option>
                                </select>
                            </div>
                            <div v-show="form.selectedTeam != null">
                                <div class="py-1 text-xs text-gray-600">Match</div>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <select  v-if="matchesInDispute.length > 0" v-model="form.selectedMatch"   name="selected_match" class="block w-full px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white bg-blue-900 border border-gray-800 rounded appearance-none form-select focus:outline-none">
                                        <option v-for="match in matchesInDispute" :key="match.id" :value="match.id">{{'vs ' + getOpposingTeam(match) }} (Match ID: #{{ match.id}})</option>
                                    </select>
                                    <div class="mt-1 font-semibold text-red-600 text-xxs" v-text="form.errors.get('match_id')"></div>
                                <input v-if="matchesInDispute.length === 0"
                                    disabled
                                    type="text"
                                    placeholder="No matches in dispute found"
                                    name="challenge_amount"
                                    class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500"
                                />
                                </div>
                            </div>

                        </div>
                        <div v-show="categoryRequiresProof" id="form-group" v-for="index in proofLinks" :key="index">
                            <div class="py-1 text-xs text-gray-600">Proof Link {{ index }}</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form.proofLinks[index]"
                                    type="text"
                                    name="challenge_amount"
                                    class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500"
                                />
                            </div>

                        </div>
                        <div @click="addProofField"  v-if="categoryRequiresProof" class="relative flex flex-wrap items-stretch w-full mb-3">
                            <div class="absolute" style="top:14px; left:12px;">
                                <i class="text-blue-500 fas fa-plus-square"></i>
                            </div>
                            <input
                                style="cursor: pointer;"
                                disabled
                                type="text"
                                name="Add Proof"
                                placeholder="     Add Proof"
                                class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Message</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <textarea v-model="form.message" rows="5" name="message" class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500 "></textarea>
                            </div>
                            <div class="mt-1 font-semibold text-red-600 text-xxs" v-text="form.errors.get('message')"></div>
                        </div>
                        <button type="submit" class="inline-block w-full px-16 py-3 mt-3 text-xs font-medium font-semibold text-center uppercase rounded btn-gradient-blue">
                            <span v-if="!formSubmitting">Submit</span>
                            <span v-if="formSubmitting"><i class="text-xl fas fa-spinner spin-medium"></i></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['categories'],
    data() {
        return {
            proofLinks: 0,
            userTeams: [],
            teamMatches: [],
            formSubmitting:false,
            selectedCategory: null,
            form: new Form({
                category:null,
                selectedTeam: null,
                selectedMatch: null,
                message: '',
                title: '',
                proofLinks: []
            })
        }
    },
    methods: {
        addProofField()
        {
            if (this.proofLinks < 5)
                this.proofLinks++;
        },
        onTeamChange()
        {
            axios.get('/api/team/'+ this.form.selectedTeam + '/challenges').then((resp) => {
                this.teamMatches = resp.data.data;
            })

            axios.get('/api/team/' + this.form.selectedTeam + '/tournament/matches').then((resp) => {
                if(resp.data.data.length) {
                    this.teamMatches = resp.data.data;
                }
            });
        },
        onCategoryChange()
        {
            this.form.category = this.selectedCategory.id;
        },
        submit()
        {
            this.formSubmitting = true;
            this.form.submit('post', '/api/tickets').then((resp) => {
                window.location.href = "/tickets"
            }).catch((errors) => {
                this.formSubmitting = false;
            })
        },
        getOpposingTeam(match)
        {
            let lookupMatch = this.teamMatches.find(t => t.id == match.id);
            if(lookupMatch.teamOne.id == this.selectedTeam)
            {
                return lookupMatch.teamOne.name;
            } else {
                return lookupMatch.teamTwo.name
            }
        }
    },
    computed: {
        categoryRequiresProof()
        {
            if (this.selectedCategory === null)
            {
                return false;
            }

            return this.selectedCategory.requires_proof;
        },
        categoryIsDispute()
        {
            if(this.selectedCategory != null)
            {
                if (this.selectedCategory.name === "Match Dispute")
                {
                    axios.get('/api/user/teams').then((resp) => {
                        this.userTeams = resp.data.data;
                    })
                    return true;
                }
            }

            return false;
        },
        matchesInDispute()
        {
            var matchesInDispute = this.teamMatches.filter(function (match) {
                if (match.disputed === 1) {
                    return match;
                }
            })

            return matchesInDispute;
        }
    }
}
</script>

<style>

</style>
