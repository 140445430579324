// import Vue from 'vue';
import {createStore} from 'vuex';

import conversations from './modules/conversations'
import leagues from './modules/leagues'
import league from './modules/league'

// Vue.use(Vuex);

export default createStore({
    modules: {
        conversations,
        leagues,
        league
    }
});
