<template>
<div>
    <div id="form-group">
        <div class="text-xs text-gray-600 py-1">Team</div>
        <div class="flex flex-wrap items-stretch w-full mb-3 relative">
            <select v-model="team_id" @change="teamSelectionChanged($event)" name="team_id" class=" form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.name }}</option>
            </select>
        </div>
    </div>
    <div v-if="selectedTeam">
        <div class="flex justify-between mb-1 items-center" v-for="member in selectedTeam.members" :key="member.id">
            <div class="flex py-3 px-3  items-center rounded-lg bg-blue-800 mb-1 w-full">
                <div>
                    <div>
                        <label class="text-gray-500 font-bold">
                            <input :id="member.username" :value="member.id" v-model="selectedRoster" class="mr-2 leading-tight" type="checkbox">
                        </label>
                    </div>
                </div>
                <img :src="member.storage_avatar" class="mr-2 rounded-lg w-8 h-8">
                <div class="leading-none">
                    <div class="font-semibold text-xs">{{ member.username }}</div>
                </div>
            </div>
        </div>
    </div>
            <input type="hidden" name="selected_roster" v-model="selectedRoster">
            <input type="hidden" name="team_id" v-model="team_id">
</div>
</template>

<script>
import api from './../store/api/all'
export default {
    props: ['teams'],
    data() {
        return {
            selectedRoster: [],
            team_id: null,
            selectedTeam: null,
        }
    },
    methods: {
        teamSelectionChanged(event)
        {
            this.selectedRoster = [];
            api.getTeam(this.team_id).then((resp) => {
                this.selectedTeam = resp.data.data
            });
        },
    }
}
</script>

<style>

</style>
