<template>
    <modal name="deposit-modal" class="flex bg-blue-700 w-1/4 rounded-lg border-gray-800 border flex-col text-white">
    

    </modal>
</template>
<script>
export default {
    name: 'deposit-modal',
}
</script>