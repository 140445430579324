<template>
    <div>
        <div class="flex justify-between py-5">
            <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" @click="submit">
                <span v-if="!processing">Save</span>
                <span v-else>Processing...</span>
            </button>
            <span class="text-green-600 mr-2" v-show="success">Changes Saved!</span>
        </div>
        <draggable
            :list="list"
            :disabled="!enabled"
            class="list-group"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
        >
            <div
                class="bg-white border-b p-3 cursor-pointer"
                v-for="(team,index) in teams"
                :key="index"
            >
                {{ team.team.name }}
            </div>
        </draggable>
    </div>
</template>

<script>
import axios from 'axios';
// import draggable from 'vuedraggable'
export default {
    name: "GroupTeamsList",
    components: {
        // draggable,
    },
    props: {
        teams: {
            type: Array,
            required:true
        }
    },
    data() {
        return {
            enabled: true,
            list: this.teams,
            dragging: false,
            processing: false,
            success: false
        };
    },
    methods: {
        submit() {
            this.processing = !this.processing;
            if (this.success) {
                this.success = !this.success;
            }
            axios
                .post('/admin/league/groupTeams/sort/update', {
                    list: this.list
                })
                .then((res) => {
                    this.success = !this.success;
                })
                .catch((e) => alert(e))
                .finally(() => this.processing = !this.processing)
        }
    }
}
</script>

<style scoped>

</style>
